import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import RedouteThemeProvider, { redouteTheme, RedouteGlobalStyles } from './lib/index.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "theme"
    }}>{`Theme`}</h1>
    <p>{`Three components for Theme`}</p>
    <Playground __position={0} __code={'<ol>\n  <li>\n    <strong>RedouteGlobalStyles</strong> writes the global css declaration in\n    the <strong>body</strong>\n    <RedouteGlobalStyles />\n  </li>\n  <li>\n    <RedouteThemeProvider>\n      <strong>RedouteThemeProvider</strong> allows any{\' \'}\n      <strong>@emotion/styled</strong> element to access{\' \'}\n      <strong>props.theme</strong>\n    </RedouteThemeProvider>\n  </li>\n  <li>\n    the <strong>redouteTheme</strong> object{\' \'}\n    <pre>{JSON.stringify(redouteTheme, null, 1)}</pre>\n  </li>\n</ol>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      RedouteThemeProvider,
      redouteTheme,
      RedouteGlobalStyles,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <ol>
        <li><strong>RedouteGlobalStyles</strong> writes the global css declaration in the <strong>body</strong><RedouteGlobalStyles mdxType="RedouteGlobalStyles" /></li>
        <li>
            <RedouteThemeProvider mdxType="RedouteThemeProvider">
                <strong>RedouteThemeProvider</strong> allows any <strong>@emotion/styled</strong> element to access <strong>props.theme</strong>
            </RedouteThemeProvider>
        </li>
        <li>the <strong>redouteTheme</strong> object <pre>{JSON.stringify(redouteTheme, null, 1)}</pre></li>
    </ol>
    
    
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      